import React from "react"
import { Link } from "gatsby"
import getValue from "get-value"
import {getUriFromDocument, getUriByTypeAndSlug} from '@utils/routing'
import {getImageData, placeholderImageId} from '@utils/image'
import Image from "@components/image"

const BlogListItem = ({
  _type,
  title,
  slug,
  className,
  mainImage,
  categories = [],
}) => {
  const categorySlug = categories !== null ? categories[0]?.slug?.current == undefined ? "" :`/${categories[0]?.slug?.current}` : '';
  const altText = mainImage?.asset?.altText || mainImage?.asset?.title || mainImage?.asset?.originalFilename?.replace(/\.(.*)$/, '').replace(/[^a-zA-Z0-9]+/g, ' ' )
  const url = getUriByTypeAndSlug(_type, slug, categorySlug )
  const imageAssetId = getValue(mainImage, "asset._id") || placeholderImageId
  const imageData = getImageData(imageAssetId, {width: 500, height: 333, fit: 'crop' })

  return (
    <div
      className={`flex flex-col gap-5 text-center sm:text-left group ${
        className ? className : ""
      }`}
    >
      {imageData && (
        <div className="relative overflow-hidden rounded-xl">
          <Image
            image={imageData}
            width={420}
            height={280}
            alt={altText}
            to={url}
            className=" w-full h-full group-hover:scale-105 transition-all duration-300"
          />
        </div>
      )}
      <div className="flex flex-col gap-2">
        <nav>
          <ul className="text-left">
            {categories?.map((category, index) => (
              <li className="inline" key={category.uid}>
                <Link
                  to={getUriFromDocument(category)}
                  className="inline-block"
                  title={"Blog Category: " + category.title}
                >
                  <span className="text-black text-xs leading-5">
                    {index !== 0 && <span className="px-2">|</span>}
                    {category.title}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <Link
          to={url}
          className="block"
          title={"Blog item: " + title}
        >
          <h3 className="text-base text-left font-medium leading-normal group-hover:text-safety-green">
            {title}
          </h3>
        </Link>
      </div>
    </div>
  )
}

export default BlogListItem
